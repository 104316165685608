<template>
  <section>
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10" v-html="item.content"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'pagePosition', 'siblings-components'],
  name: 'HtmlEmbed',
  mixins: [ReadyHook]
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    margin: 10rem 0;
    color: var(--zenith-black);
    font-size: em(16);
    line-height: 1.8;
    letter-spacing: em(0.2, 16);

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }

    /deep/ .table-container {
      overflow-x: auto;

      table {
        text-align: left;
        font-size: em(14);
        line-height: 1.8;
        letter-spacing: em(0.2, 14);
        border-collapse: collapse;
        border-spacing: 0;

        &.rates {
          min-width: 80rem;
        }

        a {
          color: var(--zenith-black);
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }

        sup {
          font-weight: normal;
          font-size: em(8);
          padding-left: 0.2rem;
        }
        thead {
          vertical-align: top;
          tr {
            border-bottom: 0.1rem solid var(--zenith-border);
            th {
              padding: 1rem;
              font-weight: normal;
            }
          }
        }
        tbody {
          vertical-align: top;
          tr {
            border-bottom: 0.1rem solid var(--zenith-table-border);
            transition: background-color .4s linear;
            &:last-child {
              border:none;
            }
            &:hover {
              background-color: var(--zenith-table-background-hover);
            }
            td, th {
              padding: 1rem;
              font-weight: normal;
              ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
              }
              &.description {
                color: var(--zenith-table-secondary-text);
              }
              &.small {
                font-size: em(11);
                letter-spacing: em(0.2, 12);
              }
            }
          }
        }
      }
    }
    /deep/ .table-informations {
      caption-side: bottom;
      text-align: left;
      margin-top: 6rem;
      font-size: em(9);
      letter-spacing: em(0.2, 12);
      font-style: italic;
      color: var(--zenith-table-secondary-text);
      a {
        color: var(--zenith-black);
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
      }
      sup {
        font-size: em(10);
      }
      ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }
  }

</style>
