<template>
  <section class="youtube_embed editorial-content">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <Observer name="fade-up" @enter="enter" @leave="leave" rootMargin="0px">
            <h2 :class="'h2 title text-' + item.title_align " v-html="item.title" v-if="item.title"></h2>
            <div class="video-wrapper">
              <iframe ref="youtubeIframe" :title="item.title || 'Youtube iframe'" width="560" height="315" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'pagePosition', 'siblings-components'],
  name: 'youtube_embed',
  mixins: [ReadyHook],
  data () {
    return {
      injected: false
    }
  },
  methods: {
    enter() {
      if (!this.injected) {
        this.injected = true
        this.$refs.youtubeIframe.src = `https://www.youtube-nocookie.com/embed/${this.item.video_id}?loop=1&mute=0&showinfo=0&rel=0&enablejsapi=1`
      }
    },
    leave() {}
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    margin: 10rem 0;
    color: var(--zenith-black);

    &.editorial-content + .editorial-content {
      margin-top: -6rem;

      @include bp(max md) {
        margin-top: -2rem;
      }
    }

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  }

  .obs-fade-up {

    .wysiwyg {
      opacity:0;
      transform: translateY(8rem);
    }
  }
  .title {
    font-weight: 500;
    font-size: em(26);
    margin-bottom: 2rem;

    @include bp(max md) {
      font-size: em(21);
      line-height: em(28, 21);
      letter-spacing: em(.2, 21);
    }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

</style>
