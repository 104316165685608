<template>
  <section class="title_text_image editorial-content">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <Observer name="fade-up">
            <h2 class="a11y-sr-only" v-html="item.title" v-if="item.title"></h2>
            <h2 data-splitting aria-hidden="true" :class="'h2 title text-' + item.title_align " v-html="item.title" v-if="item.title"></h2>
            <div>
              <div class="img-container" :style="'padding-bottom:'+ ((item.image.height / item.image.width  ) * 55) +'%'" :class="'img-' + item.image_align" >
                <img :src="item.image.url + item.image.filter" :alt="item.image.alt || ' '" v-if="item.image">
              </div>
              <div v-html="item.text" :class="'wysiwyg text-' + item.title_align " data-aos="fade-in" v-if="item.text"></div>
            </div>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'title_text_image',

  mounted () {
    this.$nextTick(() => {
      if (process.browser) {
        const Splitting = require('splitting')
        Splitting()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    position: relative;
    margin: 10rem 0;
    color: var(--zenith-component-text);

    &.editorial-content + .editorial-content {
      margin-top: -6rem;

      @include bp(max md) {
        margin-top: -2rem;
      }
    }

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  }

  .obs-fade-up {

    .title {
      color: var(--zenith-black);
      /deep/ .char {
        opacity:0;
        transform: translateX(2rem);
      }
    }

    .wysiwyg {
      opacity:0;
      transform: translateY(8rem);
    }
    .img-container {
      img {
        filter: brightness(0) #{"grayscale(5)"};
        transform: translateZ(0) scale(1.2, 1.2);
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: em(26);
    margin-bottom: 2rem;

    @include bp(max md) {
      font-size: em(21);
      line-height: em(28, 21);
      letter-spacing: em(.2, 21);
    }

    /deep/ .char {
      display: inline-block;
      @include appear();
      transition-delay: calc(20ms * var(--char-index));

    }

  }

  .img-container {
    position:relative;
    width:100%;
    overflow: hidden;
    margin: 0 0 4rem 0;

    &.img-left {
      width: 55%;
      float: left;
      margin: 0 4rem 2rem -6rem;
      object-fit: cover;

      @include bp(max md) {
        width: 100%;
        float: none;
        margin: 0 0 4rem 0;
      }
    }

    &.img-right {
      width: 55%;
      float: right;
      margin: 0 -6rem 2rem 4rem;

      @include bp(max md) {
        width: 100%;
        float: none;
        margin: 0 0 4rem 0;
      }
    }

    img {
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      backface-visibility: hidden;
      fillter: #{"grayscale(none)"} brightness(none);
      transform: translateZ(0) scale(1, 1);
      @include appearImg();
    }
  }

  .wysiwyg {
    @include appear(200ms);

    /deep/ ul, /deep/ ol {
      padding: 0;
      margin-bottom: 2rem;
      font-size: em(20);
      line-height: 1.8;
      letter-spacing: em(0.2, 20);
      list-style: none;

      li:before {
        content: '-';
        margin-right: 1.4rem;
      }

      @include bp(max md) {
        font-size: em(18);
        font-weight: normal;
        line-height: em(28, 18);
        letter-spacing: em(.2, 18);
      }
    }

    /deep/ p {
      margin-bottom: 2rem;
      font-size: em(20);
      line-height: 1.8;
      letter-spacing: em(0.2, 18);

      @include bp(max md) {
        font-size: em(18);
        font-weight: normal;
        line-height: em(28, 18);
        letter-spacing: em(.2, 18);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
