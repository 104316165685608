<template>
  <section :id="'timeline-section-' + pagePosition" ref="section" class="-visibility-auto" :data-index="pagePosition">
    <Observer name="fade" @enter="enter">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-10">
            <HeadlineTitleText :item="item"></HeadlineTitleText>
          </div>
        </div>
      </div>
    </Observer>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import HeadlineTitleText from '../components/utils/HeadlineTitleText'

export default {
  mixins: [ReadyHook],
  name: 'generic_grid',
  props: ['item', 'items', 'pagePosition', 'siblings-components', 'declination'],
  components: {
    HeadlineTitleText
  },
  data () {
    return {
      appeared: false
    }
  },
  computed: {
    localizedUrl () {
      const { url } = currentStoreView()
      return url || ''
    }
  },
  methods: {
    enter () {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    }
  },
  created () {},
  mounted () {},
  destroyed () {}
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  margin-top: 8rem;
  margin-bottom: 8rem;

  @include bp(max md) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
</style>
