<template>
  <section class="quote editorial-content">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <Observer name="fade-up">
            <blockquote :cite="item.link">
              <p v-if="item.text" v-html="item.text"></p>
              <footer v-if="item.footer">
                <span v-html="item.footer"></span>
              </footer>
            </blockquote>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ReadyHook } from 'vue-asyncready'
export default {
  props: ['item', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'quote'
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    margin: 10rem 0;

    &.editorial-content + .editorial-content {
      margin-top: -6rem;

      @include bp(max md) {
        margin-top: -2rem;
      }
    }

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  }

  blockquote {
    margin: 4rem 0;
    position: relative;
    padding-left: 3rem;
    text-align: left;
    color:var(--zenith-component-text);

    @include bp(max md) {
      margin: 2rem 0;
    }

    &:before {
      content: '';
      position:absolute;
      top:0;
      left: 0;
      width: 1px;
      height:100%;
      background: #c1c1c1;
      pointer-events: none;
      transition: all 0.8s $ExpoEaseOut;
    }

    p {
      position: relative;
      display: inline;
      font-size: em(42);
      line-height: em(48, 42);
      font-weight: 500;
      @include appear(100ms);

      @include bp(max md) {
        font-size: em(22);
        font-weight: normal;
        line-height: em(28, 18);
        letter-spacing: em(.2, 18);
      }

      &:before {
        content: "“";
      }
      &:after {
        content: "”";
      }
    }

    footer {
      margin-top: 2rem;
      color: var(--zenith-grey);
      font-size: em(16);
      @include appear(200ms);
    }
  }

  .obs-fade-up {
    blockquote {
      &:before {
        height:0;
      }
      p {
        opacity: 0;
      }
      footer {
        opacity:0;
        transform: translateX(2rem);
      }
    }
  }

</style>
