<template>
  <section class="-white -layer" :class="'-' + item.align">
    <h1 class="background">
      <parallax ref="parallax" v-if="item.type === 'video'" :from="{ y: -100 }" :to="{ y: 100 }">
        <video ref="video" autoplay playsinline loop muted slot-scope="{ parallax }" :style="`transform: translate3d(0px, ${parallax && parallax.y ? Math.round(parallax.y) : 0}px, 0px)`">
          <source ref="source" :data-src="item.video.url" type="video/mp4" />
        </video>
      </parallax>
      <parallax ref="parallax" v-if="item.type === 'image'" :from="{ y: -100 }" :to="{ y: 100 }">
        <img
          ref="picture"
          :src="imageLowDefinition + item.image.filter"
          :alt="item.image.alt || item.title || ' '"
          slot-scope="{ parallax }"
          :style="`transform: translate3d(0px, ${parallax && parallax.y ? Math.round(parallax.y) : 0}px, 0px)`"
        />
      </parallax>
    </h1>
    <Observer name="fade-up" @enter="enter" @leave="leave" root-margin="0px">
      <div class="container">
        <h2 class="h1 -upper" v-if="item.title" v-text="item.title"></h2>
      </div>
    </Observer>
    <NoSSR>
      <div class="countdown">
        <transition name="fade">
          <div v-if="countdownEnded" key="btn">
            <router-link v-if="item.ctaLink !== '/'" class="btn -white" :to="localizedUrl + item.ctaLink">{{ item.ctaText }}</router-link>
            <a v-else class="btn -white" :href="absoluteUrl">{{ item.ctaText }}</a>
          </div>
          <div class="wrapper" v-else key="count">
            <h2 class="countdown-title">{{ $t('Coming soon') }}</h2>
            <Countdown :time="countdownLimit" tag="div" :transform="transform" @end="countdownEnded = true">
              <template slot-scope="props">
                <div class="countdown-digit">
                  <strong>{{ props.days }}</strong
                  >{{ $t('days') }}
                </div>
                <div class="countdown-digit">
                  <strong>{{ props.hours }}</strong
                  >{{ $t('hrs') }}
                </div>
                <div class="countdown-digit">
                  <strong>{{ props.minutes }}</strong
                  >{{ $t('min') }}
                </div>
                <div class="countdown-digit">
                  <strong>{{ props.seconds }}</strong
                  >{{ $t('sec') }}
                </div>
              </template>
            </Countdown>
          </div>
        </transition>
      </div>
    </NoSSR>
    <button v-if="item.type === 'video'" type="button" class="btn-autoPlay" :class="{ '-paused': pauseVideo }" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo"></button>
  </section>
</template>

<script>
import { Parallax } from 'leaps/dist/leaps'
import { ReadyHook } from 'vue-asyncready'
import Countdown from '@chenfengyuan/vue-countdown'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
// import { chronomasterNow } from '../utilities'
import config from 'config'

export default {
  props: ['item', 'pagePosition', 'siblings-components'],
  name: 'countdown_banner',
  mixins: [ReadyHook],
  components: {
    NoSSR,
    Parallax,
    Countdown,
  },
  data() {
    return {
      pauseVideo: false,
      appeared: false,
      countdownLimit: 12000,
      countdownEnded: false,
    }
  },
  computed: {
    absoluteUrl() {
      return config.client.url + this.localizedUrl
    },
    localizedUrl() {
      const { url } = currentStoreView()
      return url
    },
    imageUrl() {
      return this.item.image.url
    },
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.imageUrl, 800, 0.596248766)
    },
  },
  mounted() {
    // chronomasterNow().then(({ timestamp }) => {
    //   const now = timestamp * 1000
    //   this.countdownLimit = +new Date(this.item.date) - +new Date(now) >= 0 ? +new Date(this.item.date) - +new Date(now) : 0
    // })
    this.countdownLimit = 0;
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value
        props[key] = `${digits}`
      })
      return props
    },
    toggleVideo() {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    },
    enter() {
      if (!this.appeared) {
        if (this.$refs.parallax) {
          this.$refs.parallax.updateConfig()
          this.$refs.parallax.update()
        }
        this.appeared = true
        if (this.$refs.video) {
          this.$refs.source.setAttribute('src', this.$refs.source.dataset.src)
          this.$refs.source.removeAttribute('data-src')
          setTimeout(() => {
            this.$refs.video.load()
          }, 200)
        } else {
          if (this.$refs.picture) this.$refs.picture.srcset = ImageManager.getSrcSet(this.imageUrl, 1600, 600, 100, 0.596248766)
        }
      } else if (this.$refs.video && this.$refs.video.paused) this.$refs.video.play()
    },
    leave() {
      if (this.$refs.video && !this.$refs.video.paused) this.$refs.video.pause()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  display: flex;
  padding: 17rem 0;
  overflow: hidden;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  // min-height: calc(100vw / 1.8);
  height: calc(100vh - 120px);
  background: black;
  transform-style: preserve-3d;

  @include bp(max md) {
    height: 100vh;
    padding: 10rem 0;
  }

  &.-bottom {
    .obs {
      margin-top: auto;
      .h1 {
        margin-bottom: 8rem;
      }
    }
  }

  & > div {
    width: 100%;
  }

  *:not(video) {
    position: relative;
    z-index: 20;
  }

  h1.background {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .h1 {
    margin-bottom: 0;
    color: var(--zenith-component-title);
  }

  &.-white {
    .h2,
    .h5 {
      color: var(--zenith-component-title);
    }
  }

  video,
  img {
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;
    height: calc(100% + 10rem);
    object-fit: cover;
    pointer-events: none;
  }

  button.btn-scroll {
    position: absolute;
    left: 50%;
    bottom: 12rem;
    width: 4.8rem;
    color: var(--zenith-component-title);
    padding: 1.3rem 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid var(--zenith-component-title);
    background: transparent;
    transform: translateX(-50%);

    @include bp(max md) {
      bottom: 5rem;
    }

    i {
      display: block;
      font-size: em(20);
      line-height: 2rem;
      animation: bouncing 1s infinite ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
      background: var(--zenith-component-hero-static-banner-btn-background);
      color: var(--zenith-component-hero-static-banner-btn-hover);
    }
  }
}
@keyframes bouncing {
  0%,
  100% {
    transform: translateY(-0.3rem);
  }
  25% {
    transform: translateY(0.7rem);
  }
}

.countdown {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  color: white;
  text-align: center;
  transform: translateX(-50%);

  .wrapper > div {
    display: flex;
    justify-content: center;
  }

  &-title {
    margin: 0;
    font-size: em(22);
    letter-spacing: em(2, 22);
    font-weight: normal;
    text-transform: uppercase;
  }

  &-digit {
    position: relative;
    width: 8rem;
    font-size: em(16);
    text-transform: uppercase;

    &:not(:last-child):after {
      position: absolute;
      top: 2%;
      right: 0;
      content: ':';
      transform: translateX(50%);
    }

    strong {
      display: block;
      font-size: em(28, 16);
      line-height: em(28, 28);
    }
  }

  .btn {
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.btn-autoPlay {
  position: absolute;
}
</style>
