<template>
  <section class="title_image editorial-content">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <Observer name="fade-up" @enter="enter">
            <h2 :class="'h2 title text-' + item.title_align " v-html="item.title" v-if="item.title"></h2>
            <template v-if="item.image">
              <figure>
                <div class="img-container" :style="'padding-bottom:'+ ((item.image.height / item.image.width  ) * 100) +'%'">
                    <img ref="picture" :src="imageLowDefinition + item.image.filter" :alt="item.image.alt || ' '" sizes="
            (max-width: 992px) 100vw,
            895px">
                </div>
                <figcaption v-html="item.image.legend" v-if="item.image.legend"></figcaption>
              </figure>
            </template>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { ReadyHook } from 'vue-asyncready'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'title_image',
  data () {
    return {
      appeared: false
    }
  },
  computed: {
    imageUrl() {
      return this.item.image.url
    },
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.imageUrl, 400, 0.6662606577)
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.appeared = true
        if (this.$refs.picture) this.$refs.picture.srcset = ImageManager.getSrcSet(this.imageUrl, 1600, 400, 100, 0.6662606577)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    position: relative;
    margin: 10rem 0;

    &.editorial-content + .editorial-content {
      margin-top: -6rem;

      @include bp(max md) {
        margin-top: -2rem;
      }
    }

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  }

  .obs-fade-up {
    .img-container {
      img {
        filter: brightness(0) #{"grayscale(5)"};
        transform: scale(1.1, 1.1);
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: em(26);
    margin-bottom: 2rem;
    color: var(--zenith-black);

    @include bp(max md) {
      font-size: em(21);
      line-height: em(28, 21);
      letter-spacing: em(.2, 21);
    }
  }

  figure {
    margin: 0;
    figcaption {
      margin-top: 1rem;
      text-align: right;
      color: var(--zenith-grey);
    }
  }
  .img-container {
    position:relative;
    width:100%;
    overflow: hidden;

    img {
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
      filter: #{"grayscale(none)"} brightness(none);
      @include appearImg(100ms);
    }
  }
</style>
